@import "https://unpkg.com/modern-css-reset/dist/reset.min.css";
@import "https://unpkg.com/@contentful/f36-tokens@4.0.1/dist/css/index.css";
@import "https://www.contentful.com/fonts.css";
body, html {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
}

a {
  color: var(--color-text);
  font-weight: 700;
}

main {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

body {
  -webkit-font-smoothing: antialiased;
  color: var(--text-color);
  background: var(--bkg);
  font-family: Avenir Next W01;
  line-height: var(--line-height-default);
  margin: 0;
}

button {
  border-radius: var(--border-radius-medium);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-condensed);
  padding: var(--spacing-2xs) var(--spacing-m);
  cursor: pointer;
  color: var(--color-white);
  background: var(--blue-500);
  border: 1px solid;
  border-color: var(--blue-500);
  outline: none;
  min-height: 32px;
  text-decoration: none;
  box-shadow: 0 1px #19253214;
}

button:hover {
  background: var(--blue-600);
  border-color: var(--blue-600);
}

button:focus {
  border-color: var(--blue-600);
  box-shadow: var(--glow-primary);
}

button:focus:not(:focus-visible) {
  border-color: var(--color-blue500);
  box-shadow: unset;
}

button:focus-visible {
  border-color: var(--color-blue-600);
  box-shadow: var(--glow-primary);
}

button > a {
  font-weight: normal;
  text-decoration: none;
}

button.button-secondary {
  background: var(--gray-200);
  color: var(--gray-900);
  background: var(--color-white);
  border-color: var(--gray-300);
}

button.button-large {
  font-weight: var(--font-size-xl);
  line-height: var(--line-height-xl);
  padding: var(--spacing-xs) var(--spacing-m);
  min-height: 48px;
}

h1, h2, h3, h4, h5 {
  margin-bottom: var(--spacing-l);
}

h1, h2, h3 {
  letter-spacing: -.02rem;
  font-weight: 800;
}

h1 {
  font-size: var(--font-size-4xl);
}

h2 {
  font-size: var(--font-size-3xl);
}

h3 {
  font-size: var(--font-size-2xl);
}

h4 {
  font-size: var(--font-size-xl);
}

h5 {
  font-size: var(--font-size-l);
}

p {
  font-size: var(--font-size-l);
  line-height: var(--line-height-m);
  margin: var(--spacing-m) 0;
}

small {
  letter-spacing: .08em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: .8rem;
  font-weight: 500;
  line-height: 1.1;
  display: block;
}

.grid {
  grid-gap: var(--spacing-xs);
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  display: grid;
}

.painting {
  text-align: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-300);
  margin: 5px;
}

.painting h4 {
  margin-bottom: 0;
}

.painting p {
  margin-top: 0;
}

.painting img {
  object-fit: cover;
  width: 100%;
  height: 350px;
}

.river {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin-top: 3rem;
  display: flex;
}

.river article {
  width: 100%;
}

.river article button:first-child {
  margin-right: var(--spacing-m);
}

.river > img {
  width: 100%;
  max-width: 450px;
}

.tags {
  justify-content: center;
  margin-top: 3rem;
  display: flex;
}

.tags label {
  cursor: pointer;
}

@media screen and (width >= 40em) {
  .river:nth-child(2n) {
    flex-direction: row-reverse;
  }

  .river:nth-child(odd) {
    flex-direction: row;
  }

  .river article, .river > img {
    width: 50%;
  }
}

:where .flash-news-container {
  min-height: 100vh;
  padding: 0 1rem;
}

body {
  background-color: #f0f0f0;
}
/*# sourceMappingURL=index.e7b86dd7.css.map */
