.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: var(--spacing-xs);
}

.painting {
  text-align: center;
  margin: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-300);
}

.painting h4 {
  margin-bottom: 0;
}

.painting p {
  margin-top: 0;
}

.painting img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.river {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-direction: column;
  margin-top: 3rem;
}

.river article {
  width: 100%;
}

.river article button:first-child {
  margin-right: var(--spacing-m);
}

.river > img {
  width: 100%;
  max-width: 450px;
}

.tags {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.tags label {
  cursor: pointer;
}

@media screen and (min-width: 40em) {
  .river:nth-child(even) {
    flex-direction: row-reverse;
  }

  .river:nth-child(odd) {
    flex-direction: row;
  }

  .river article,
  .river > img {
    width: 50%;
  }
}:where

.flash-news-container {
  min-height: 100vh;
  padding: 0 1rem;
}

body {
  background-color: #f0f0f0;
}